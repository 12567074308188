.cr-page-title {
  margin-bottom: calc(#{$app-content-box-padding} * 1.5);
  background: $app-content-background;

  &.--without-title {
    margin-bottom: 0;
  }

  &.--sticky {
    position: sticky !important;
    top: 70px;
    z-index: 1 !important;
  }

  .cr-page-title-breadcrumb {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .ms-Breadcrumb {
      margin-top: -1rem;
      margin-bottom: 1rem;

      .ms-Breadcrumb-item,
      button {
        font-size: $font-size-sm;
      }

      .ms-Breadcrumb-listItem:first-child .ms-Breadcrumb-item {
        padding-left: 0;
      }

      .ms-Breadcrumb-itemLink:hover {
        background: transparent;
        color: $color-primary;
      } 
    }
    .cr-page-title-extra-breadcrumb {
      position: relative;
      font-size: $font-size-sm;
      margin-top: -1rem;
      margin-bottom: 1rem;
    }
  }

  .cr-page-title-header {
    $-icon-size: 4rem;
    $-icon-spacing: 1rem;
    $-icon-region: $-icon-size + $-icon-spacing;

    @include transition(0.2s);

    display: flex;
    flex-basis: 100%;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    @media (max-width: $break-md) {
      .btn {
        a,
        .al-button {
          display: block;
          width: 100%;
          text-decoration: none;
        }
      }
    }

    .head {
      display: flex;
      align-items: center;

      .icon {
        @include transition(0.2s);

        position: relative;
        display: block;
        flex-grow: 0;
        flex-shrink: 0;
        width: $-icon-size;
        height: $-icon-size;

        border-radius: $app-content-box-radius;
        background: $app-content-box-background;
        box-shadow: $app-content-box-shadow;
        line-height: $-icon-size;
        text-align: center;

        i {
          $-size: $font-size-4xl;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: calc(#{$-size} / -2);
          margin-left: calc(#{$-size} / -2.2);
          font-size: $-size;

            &.maven-logo-icon {
              top:0;
              left:0;
              width:100%;
              height:100%;
              margin: 0;
              background: url("../../image/logo_maven_icon_black.png") center center / auto 20px no-repeat;
            }
        }
        
      }

      .title {
        flex-basis: 100%;
        margin-left: 1rem;
        overflow: hidden;

        strong {
          display: block;
          font-size: $font-size-2xl;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          .ms-Shimmer-shimmerWrapper {
            max-width: 300px;
          }
        }

        .desc {
          display: block;
          margin: 0.5rem 0 0 0;
          font-size: $font-size-base;
          line-height: 1.3;

          .ms-Shimmer-shimmerWrapper {
            max-width: 500px;
          }
        }
      }
    }

    .btn {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      text-align: right;
      padding: 0.75rem 0;

      .ms-Button-label {
        white-space: nowrap;
      }

      > * {
        margin-left: 5px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .cr-page-title-message {
    margin-top: $app-content-box-padding;

    .ms-MessageBar-content {
    }
  }
}
